$bannerPadding: 1.1em;
$bannerPaddingBottom: 1.5em;
$bannerPaddingSmall: 1em;
$bannerpaddingBottomSmall: 1.2em;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

html {
  height: 100%
}

.bg {
  height: 50vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.65;
  transition: 1s;

  &.s4 {
    background-image: url("./assets/7810-03-sivacon-s4-180509-1-highres-srgb_original.jpeg");
  }

  &.s8 {
    background-image: url("./assets/keyvisual-egm-s8-cmyk-hires-BI-neu-RGB_original.jpg");
  }

  &:hover {
    opacity: 1;
    transition: 1s;
  }
}

.caption {
  position: absolute;
  width: auto;
  text-align: center;
  background: rgba(80, 190, 190, 0.7);
  background: -moz-linear-gradient(to right, rgba(80, 190, 190, 0.7) 0, rgba(0, 153, 153, 0.7) 50%, rgba(0, 153, 176, 0.7) 83%, rgba(0, 153, 203, 0.7) 100%);
  background: -webkit-linear-gradient(to right, rgba(80, 190, 190, 0.7) 0, rgba(0, 153, 153, 0.7) 50%, rgba(0, 153, 176, 0.7) 83%, rgba(0, 153, 203, 0.7) 100%);
  background: linear-gradient(to right, rgba(80, 190, 190, 0.7) 0, rgba(0, 153, 153, 0.7) 50%, rgba(0, 153, 176, 0.7) 83%, rgba(0, 153, 203, 0.7) 100%);
  padding: 1.1em;
  padding-bottom: 1.5em;
  text-align: left;

  &.s4 {
    left: 10%;
    top: 25vh;
  }

  &.s8 {
    right: 10%;
    bottom: 25vh;
  }

}

.border {
  color: #fff;
  font-size: 35px;
  font-weight: bold;
  font-family: Siemens;
}

@font-face {
  font-family: Siemens;
  src: url(./assets/sisan03.woff);
}

@media screen and (max-width: 768px) {
  .caption {
    padding: 1em;
    padding-bottom: 1.2em;

    &.s4 {
      left: 0
    }

    &.s8 {
      right: 0
    }
  }

  .border {
    font-size: 25px
  }
}